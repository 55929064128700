.btn {
  text-transform: uppercase;
}

.btn-download {
  padding: 20px 35px;
  font-size: 14px;
  background: $light;
  color: $primary-color;

  span {
    margin-left: 5px;
    font-size: 20px;
  }
}

.btn-main {
  padding: 25px 45px;
  border-radius: 3px;
  background: $primary-color;
  color: $light;
  outline: none;

  &:hover {
    color: $light;
  }

  &:focus {
    color: $light;
    box-shadow: none;
  }
}

.btn-main-md {
  padding: 17px 38px;
  border-radius: 3px;
  background: $primary-color;
  color: $light;
  outline: none;

  &:hover {
    color: $light;
  }

  &:focus {
    color: $light;
    box-shadow: none;
  }
}

.btn-main-sm {
  padding: 15px 35px;
  border-radius: 3px;
  background: $primary-color;
  color: $light;
  outline: none;
  font-size: 14px;

  &:hover {
    color: $light;
  }

  &:focus {
    color: $light;
    box-shadow: none;
  }
}

.btn-white {
  background: white;
  color: $primary-color;
}

.btn-rounded-icon {
  border-radius: 100px;
  color: $light;
  border: 1px solid $light;
  padding: 13px 50px;
}